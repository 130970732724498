/** Animacion boton menu mobile */

.animated-icon2 {
    width: 30px;
    height: 20px;
    position: relative;
    margin: 0px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
}

.animated-icon2 span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    background: #fff;
}

.animated-icon2 span:nth-child(1) {
    top: 0px;
}

.animated-icon2 span:nth-child(2), .animated-icon2 span:nth-child(3) {
    top: 10px;
}

.animated-icon2 span:nth-child(4) {
    top: 20px;
}

.navbar-toggler:not(.collapsed) .animated-icon2 span:nth-child(1) {
    top: 11px;
    width: 0%;
    left: 50%;
}

.navbar-toggler:not(.collapsed) .animated-icon2 span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.navbar-toggler:not(.collapsed) .animated-icon2 span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.navbar-toggler:not(.collapsed) .animated-icon2 span:nth-child(4) {
    top: 11px;
    width: 0%;
    left: 50%;
}

/** Fin animacion boton menu mobile */







.animated-icon3 {
    width: 30px;
    height: 20px;
    position: relative;
    margin: 0px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
}

.animated-icon3 span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    background: #fff;
}

.animated-icon3 span:nth-child(1) {
    top: 0px;
}

.animated-icon3 span:nth-child(2), .animated-icon3 span:nth-child(3) {
    top: 10px;
}

.animated-icon3 span:nth-child(4) {
    top: 20px;
}

.animated-icon3 span:nth-child(1) {
    top: 11px;
    width: 0%;
    left: 50%;
}

.animated-icon3 span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.animated-icon3 span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.animated-icon3 span:nth-child(4) {
    top: 11px;
    width: 0%;
    left: 50%;
}