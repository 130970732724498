@font-face {
    font-family: 'DIRECTVCurve-Regular';
    src: url('./fonts/DIRECTVCurve-Regular.woff') format('woff');
    font-style: normal;
}

@font-face {
    font-family: 'DIRECTVCurve-Bold';
    src: url('./fonts/DIRECTVCurve-Bold.woff') format('woff');
    font-style: normal;
}

@font-face {
    font-family: 'DIRECTVCurve-Black';
    src: url('./fonts/DIRECTVCurve-Black.woff') format('woff');
    font-style: normal;
}


html, body, #root { height:100% !important; }

#root {
    display: flex;
    flex-direction: column;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
  color: #fff !important;
  font-family: 'DIRECTVCurve-Regular' !important;
}

.txtbold, strong {
    font-family: 'DIRECTVCurve-Black';
}

.text-justify {
    text-align: justify;
}

.bases-content p {
    text-align: justify;
}

.w-60 { width: 60%; }
.w-70 { width: 70%; }
.w-80 { width: 80%; }
.w-90 { width: 90%; }

.color-o { color: #F18E0C; }

code { font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

.seccion-titulo {
    font-family: 'DIRECTVCurve-Bold';
}


button:not(.navbar-toggler), .btn { 
    text-transform: uppercase !important;
    display: block;
    font-size: 1.0em;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    outline: none;
 }

button:focus, input:focus, textarea:focus, input:focus {
    outline: none !important;
    box-shadow: none !important;
}

.form-control, .form-select {
    margin-bottom: 8px;
    /* height: 36px; */
    width: 100%;
    padding: .375rem .95rem !important;
}

.nav-link {
    color: #fff !important;
    font-family: 'DIRECTVCurve-Bold';
}

.nav-link.active {
    font-family: 'DIRECTVCurve-Black';
}

form { text-align: left; }
form a, form a:visited, form a:hover { color: #fff; }

form#registro, form#ingresar, form#participar, form#promocode-form, form#opciones-form  {
    max-width: 440px;
    text-align: start;
    margin: 0 auto;
}

.m-width {
    width: 100%;
    max-width: 440px;
    margin-left: auto;
    margin-right: auto;
}

.m-width-2 {
    width: 100%;
    max-width: 280px;
    margin-left: auto;
    margin-right: auto;
}


.nav { flex-grow: 0; }
.promo-content {text-align: center; flex-grow: 1; }
footer { flex-grow: 0; background-color: #000; }
footer .footer-logo { margin-bottom: 0px; }
footer p.legales {color: #888; text-align: center;}

.promo-content>.row { min-height: 100%;}

h1,h2,h3,h4,h5,h6 { 
    font-weight: bolder; 
    /*text-transform: uppercase;*/
}

.form-check>label { /*font-size: 0.8em;*/ }
/*.form-check>label>a { color: #fff !important; }*/

.btn.active { box-shadow: 0 0 10px #000; background-color: rgb(0, 38, 50) !important; }


.boxw {
    padding: 30px;
    border-radius: 30px;
    border-top-right-radius: 0px;
    background: #fff;
    width: auto;
    display: inline-block;
    margin: 20px auto;
    color: #D60020;
    max-width: 600px;
}

.promo-section-ganadores .items {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.promo-section-ganadores .item {
    margin: 5px;
    font-size: 1.5em;
    width: auto;
    border: 1px solid #fff;
    border-radius: 25px;
    font-family: 'ColgateReady-Heavy';
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    white-space: nowrap;
}

body.promo-section.swal2-height-auto {
    height: 100%!important;
}
.swal2-icon { display: none !important; }
.swal2-popup { 
    /*border-radius: 30px !important;*/
    background-color: #000 !important;
    color: #fff !important;
}
.swal2-popup button, .boxw .btn {
    /*
    border-radius: 25px !important;
    color: #D60020 !important;
    border: 1px solid #D60020 !important;
    */
    text-transform: none !important;
    display: inline-block;
    width: auto;
} 
.swal2-popup button.swal2-cancel {
    /*background-color: #D60020 !important;
    color: #fff !important;
    border: 1px solid #D60020 !important;*/
} 

.c2 {
    font-family: 'ColgateReady' !important;
    font-style: italic;
}

/*
.os-scrollbar-track { background-color: #eee !important; }
.os-scrollbar-handle { background-color: #ccc !important; }
.os-scrollbar-vertical, .os-theme-light > .os-scrollbar-vertical { width: 15px !important; }
.os-content { padding-right: 15px !important; }
*/

.barra-usuario {
    background-color: #d02937;
    box-shadow: 0 1px 4px 0px #666;
    position: relative;
    z-index: 100;
    padding: 5px;
}

.barra-usuario label, .usuario {
    padding-right: 5px;
    text-transform: uppercase;
}

.barra-usuario input, .barra-usuario button, .form-group-inline {
    display: inline;
    width: auto;
}

.barra-usuario input, .barra-usuario button {
    border-radius: 10px;
 }

.barra-usuario .form-group-inline {
    padding-left: 5px;
    padding-right: 5px;
}

.user-circle {
    width: 2.2em;
    height: 2.2em;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    color: #F18E0C;
    font-family: 'DIRECTVCurve-Bold';
}

.menu-user .user-circle {
    font-family: 'DIRECTVCurve-Bold';
    font-size: 4em;
    margin-bottom: 0.3em;
}

.menu-user .user-name {
    font-family: 'DIRECTVCurve-Bold';
}

hr { opacity: 1 !important; }

.custom-radio .form-check-input {
    float: right !important;
    margin-left: -1.5em !important;
    margin-right: 0 !important;
}

.btn.btn-pais {
    /*
    background-color: transparent !important;
    border: 0 !important;
    */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.2em;
    padding: 0.2em 2em; 
    margin: 1em auto;
}
.btn.btn-pais .flag {
    width: 4em;
    padding: 0.3em 0.7em;
}

.promo-section-premios p {
    margin-bottom: 0.5em;
}
.promo-section-premios h2 {
    font-size: 1.0em;
}

.promo-section-inicio .img-bienvenidos {
    max-width: 340px;
}

.promo-section-inicio footer .no-cliente {
    display: none;
}

.promo-section-inicio .promo-content>.row {
    height: 100%;
    align-items: center;
}

.promo-section-como-participar {
    background-color: #000 !important;
    background-image: none !important;
}

.img-premio {
    width: 100%;
    max-width: 180px;
    display: block;
    margin: 2.5em auto;
    cursor: pointer;
}

.premios-detalle {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #000;
    color: #fff;
    z-index: 200;
}

.premios-detalle .close-btn {
    position: absolute;
    top: 1.5em;
    right: 1.2em;
    width: auto;
    color: #fff;
    background-color: transparent;
    border: 0 !important;
    outline: none !important;
}

.premios-detalle-text {
    font-size: 1.1em;
}
.premios-detalle-text p {
    margin: 0;
}
.premios-detalle-text p, .premios-detalle-text h1, .premios-detalle-text h2, .premios-detalle-text h3 {
    text-align: left;
}
.premios-detalle-text hr {
    margin-top: 0.7em !important;
    margin-bottom: 1.5em !important;
    width: 100% !important;
}

.p-large { font-size: 1.1em; }
.p-small { font-size: 0.90em; }
.p-xsmall { font-size: 0.65em; }
.promo-content a, .promo-content a:visited, a.promo-content :hover, footer a, footer a:visited, footer a:hover { color: #fff; }

.res-icon {
    height: 5em;
}

.promo-section-premios hr {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2em;
    margin-bottom: 2em;
}

.premios-flag {
    width: 2.7em;
    margin-right: 0.8em;
}
.form-label {
    display: none;
}

.form-control, .form-select {
    background-color: transparent !important;
    color: #fff !important;
    border-radius: 2em !important;
    border: 2px solid #4FBED3 !important;
}

.form-control:focus, .form-select:focus {
    border-color: #F18E0C !important;
}

.form-select option {
    color: black;
    background-color: white;
  }

.btn-primary, .swal2-actions button {
    background-color: #F18E0C !important;
    color: #fff !important;
    border-radius: 2em !important;
    border: 0 !important;
    font-family: 'DIRECTVCurve-Bold';
}

.btn-primary.btn-small {
    width: 50%;
}

::placeholder {
    color: #ccc !important;
}
::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: #ccc !important;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: #ccc !important;
    opacity: 1;
}

.bw {
    background-image: url(./img/bw-top.png),  url(./img/bw-mid.png),  url(./img/bw-bottom.png);
    background-size: 100% 2em, 100% calc(100% - 4em + 2px), 100% 2em;
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-position: top center, center center, bottom center;
}
.bw.fill, .bw:hover {
    background-image: url(./img/bw-top-fill.png),  url(./img/bw-mid-fill.png),  url(./img/bw-bottom-fill.png);
}
.bw-small {
    background-size: 100% 1em, 100% calc(100% - 2em + 2px), 100% 1em;
}
.opcion.active .bw {
    background-image: url(./img/bw-top-fill-o.png),  url(./img/bw-mid-fill-o.png),  url(./img/bw-bottom-fill-o.png);
}

.respuestas {
    width: 95%;
    max-width: 440px;
    margin-left: auto;
    margin-right: auto;
}
.respuestas .respuesta {
    margin-top: 0.9em;
    margin-bottom: 0.9em;
    min-height: 4em;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.respuestas .respuesta input {
    width: 0px;
    height: 0px;
    opacity: 0;
}

.respuestas .respuesta label {
    display: block;
    width: 100%;
    text-align: left;
    padding: 0.7em 2.2em;
}

.repuesta-participante {
    width: 100%;
    max-width: 220px;
}

.opciones {
    padding-left: 0.5em;
    padding-right: 0.5em;
    max-width: 440px;
    margin-left: auto !important;
    margin-right: auto !important;
}
.opcion {
    width: 100%;
    max-width: 15em;
    position: relative;
    margin-bottom: 0.4em;
}

.opcion .imagen {
    width: 94%;
    margin: 0 auto;
    position: relative;
    z-index: 20;
    padding-bottom: 120%;
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
}

.opcion .texto {
    margin-top: -0.8em;
    position: relative;
    z-index: 40;
    padding: 0.4em 1.0em;
}

.opcion .texto h3 {
    text-transform: uppercase;
    font-size: 1.1em;
}

.btn-opcion {
    cursor: pointer;
}

.cut-corner {
    width: 100%; /* Set the width of the image */
    height: 300%; /* Maintain aspect ratio */
    clip-path: polygon(100% 100%, 100% 80%, 100% 20%, 100% 0%, 22% 0%, 0% 15%, 0% 100%, 20% 100%);
    border: 2px solid #4fbdd2;
}

.cut-corner-border-top-left {
    position: absolute;
    top: 7.1%;
    left: 0%;
    width: 27%;
    height: 0;
    transform: rotate(-41deg);
    border-top: 2px solid #4fbdd2;
    z-index: 25;
}

.opcion.active .cut-corner {
    border: 2px solid #f18e0c;
}

.opcion.active .cut-corner-border-top-left {
    border-color: #f18e0c;
}

.banner {
    width: 80%;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1em;
}

.puntos-total {
    font-size: 2.1em;
}

.puntos-table {
    font-size: 1.3em;
}

.puntos-table .borderbtm {
    border-bottom: 1px solid #fff;
}

@media (max-width: 992px) {
    .desktop { display: none !important; }

    body {
        background-image: url(./img/bg.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-position: top center;
    }

    .logo-mobile { position: absolute; /*top: 0; */left: 20px; width: 160px;}
    .user-img { width: 24px; }

    .navbar-toggler { position: relative; z-index: 130; border: 0 !important; }
    .navbar-toggler:focus { outline: none !important;  }
    .navbar {
        background-color: #000;
        min-height: 100px;
        height: 100px;
    }
    nav .navbar-collapse {
      position: fixed;
      /*top: 56px;*/
      top: 0;
      padding-top: 60px;
      background: #F18E0C;
      color: #fff;
      right: -100%;
      width: 85vw;
      height: 100vh;
      display: block;
      transition: right 0.3s ease;
      z-index: 120;
    }

    nav .navbar-collapse.show { right: 0; }

    .nav-link {text-align: left; color: #fff !important; padding: 0.15em 2em !important; font-size: 1.44em; }

    .barra-usuario input { border-radius: 10px; max-width: 75px; }

    .seccion-titulo { margin-top: 20px; margin-bottom: 15px; }
    .promo-content { padding-bottom: 30px; padding-top: 20px; }
    .footer-logo { width: 130px; margin: 10px; }
}

@media (min-width: 993px) {
    #root { text-align: center; }
    .mobile { display: none !important; }

    body {
        background-image: url(./img/bgd.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-position: top center;
    }

    .promo-section-ganadores .container {
        max-width: 100%;
        width: 100%;
    }

    .container100 {
        width: 100%;
    }

    .bases-wrapper {
        width: 55%;
        margin: 0 auto;
    }
   
    /*
    .bases-scroll, .ganadores-scroll { max-height: 380px; }
    .bases-content {
        min-height: 650px;
    }
    */
    .promo-section-bases .promo-content, 
    .promo-section-bases .promo-content .row, 
    .promo-section-bases .promo-content .col, 
    .promo-section-bases .bases-wrapper, 
    .promo-section-bases .bases-content,
    .promo-section-bases .bases-scroll, 
    .ganadores-scroll { 
        height: 95%; 
    }

    .bases-content .os-host {
        padding-right: 25px;
    }

    .bases-content .os-scrollbar-track {
        background: rgba(255,255,255,0.1) !important;
    }

    .nav-link:not(:last-child):after{
        /*content: " \B7 ";*/
        content: "  ";
        font-weight: bold;
        margin-left: 0.5em;
    }

    .seccion-titulo { margin-top: 10px; margin-bottom: 10px; }
    .promo-content { padding-top: 2em; padding-bottom: 2em; }


    /** Navbar centrada */
    .navbar { 
        display: flex !important;
        justify-content: center !important; 
        background-color: #000;
        padding-left: 20px;
        padding-right: 70px;
        /*padding-top: 0 !important;*/
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
        width: 100%;
    }
    .navbar-collapse { flex-grow: unset !important; }
    .navbar-brand { }
    .navbar-brand>img { height: 44px; width: auto; }

    .footer-logo { max-width: 130px; margin: 10px; }
}

@media (min-width: 993px) and (max-width: 1540px) {
    body {
        font-size: 0.8em;
    }
    
    .promo-content { padding-top: 0.5em !important; padding-bottom: 0.5em !important; }

    h1 {
        font-size: 1.99em !important;
    }

    h2 {
        font-size: 1.5em !important;
    }

    h3 {
        font-size: 1.4em !important;
    }

    .opciones {
        font-size: 0.7em !important;
        max-width: 300px;
    }

    .repuesta-participante {
        width: 100%;
        max-width: 180px;
    }

    .m-width {
        max-width: 380px;
        margin-left: auto;
        margin-right: auto;
    }

    #opciones-form .m-width {
        max-width: 280px;
    }
}